import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import Vue from "vue";
import router from '@/router'
export default function useFamiliesBenefList() {
  // Use toast



  // if (Designations) emit('addDesignation')
  const refFamiliesListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "first_name", label: "الاسم", sortable: true },
    {
      key: "father_name",
      label: "  اسم الأب",
      
      sortable: true,
    },
    {
      key: "last_name",
      label: "النسبة",
      formatter: title,
      sortable: true,
    },
    
    {
      key: "mother_name",
      label: " اسم الأم",
      
      sortable: true,
    },
   
    { key: "actions" ,label: "  الخيارات",},
  ];
  const perPage = ref(10);
  const totalFB = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refFamiliesListTable.value
      ? refFamiliesListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalFB.value,
    };
  });

  const refetchData = () => {
    refFamiliesListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery], () => {
    refetchData();
  });

 
  const fetchFamiliesBenef = (ctx, callback) => {
    store
      .dispatch("app-family/fetchFamiliesBenef", {
         id: router.currentRoute.params.id, 
        
         page_size: perPage.value,
         page: currentPage.value,
        
        })
      .then((response) => {
   
        const { data, total } = response.data.data
        
        callback(data)
        totalFB.value = total

     
      })
      .catch(() => {
        Vue.swal({
              title: "حدثت مشكلة في استرجاع البيانات",
      icon: "error",
           
        confirmButtonText: "موافق",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      });
  };

  return {
    fetchFamiliesBenef,

    tableColumns,
    perPage,
    currentPage,

    totalFB,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refFamiliesListTable,

    refetchData,
  };
}
